<template>
  <!-- 加入会议 -->
  <el-dialog
    :class="['custom-dialog-box', 'share-meeting-dialog-box', options.size]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <div class="share-box">
      <div class="item-box">
        <div class="key-box">会议名称：</div>
        <div class="value-box">{{ row.title }}</div>
      </div>
      <div class="item-box">
        <div class="key-box">会议时间：</div>
        <div class="value-box">{{ row.start_time }}</div>
      </div>
      <div class="item-box">
        <div class="key-box">会议号码：</div>
        <div class="value-box">
          {{ roomNumber }}
          <span
            class="copy-btn"
            @click="handleClip(roomNumber, $event)" 
            style="color: rgb(64, 158, 255); cursor: pointer; margin-left: 20px;"
            >复制</span
          >
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer" v-if="options.footerShow">
      <!-- <el-button class="custom-btn confirm-btn" @click="confirm"
        >加入会议</el-button
      > -->
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>
  
  <script>
import clipboard from "@/utils/clipboard";
export default {
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      roomNumber: "",
    };
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      // let arr = this.row.url.split("/");
      // let arr = this.row.url.split("/");
      this.roomNumber = this.row.number;
      console.log("🚀 ~ init ~ this.roomNumber:", this.roomNumber);
    },
    handleClip(text, event) {
      clipboard(text, event);
      event.stopPropagation();
    },
    close() {
      this.$emit("onFXClose");
    },
  },
};
</script>
  
  <style lang="scss">
.share-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .el-dialog {
    height: px2vh(400) !important;
  }

  .share-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
    font-size: 0.16rem;

    .item-box {
      margin: px2vh(20);
      display: flex;
      align-items: center;

      .key-box {
        padding: px2vh(10) px2vh(30);
      }

      .value-box {
        user-select: none;

        .copy-btn {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
  