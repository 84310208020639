<template>
  <!-- 分享会议 -->
  <el-dialog
    :class="['custom-dialog-box', 'join-meeting-dialog-box', options.size]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <GlobalForm
      v-if="showTable"
      ref="GlobalForm"
      class="h100 info-form-box hide-scrollbar"
      :formConfig="formConfig"
      :formData="formData"
      :form="form"
    >
    </GlobalForm>
    <span slot="footer" class="dialog-footer">
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >修改</el-button
      >
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>
  
  <script>
import { userInfo, profile } from "@/api/home.js";
export default {
  mixins: [],
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    var validateName = (rule, value, callback) => {
      const reg = /^[0-9a-zA-Z]{5,12}$/; // 正则表达式，匹配5-12位的英文或数字
      if (!reg.test(value)) {
        callback(new Error("请输入5-12位的英文或数字"));
      } else {
        callback();
      }
    };
    return {
      form: {
        avatar1: [],
        // avatar1: [
        //   {
        //     url: "/uploads/20240119/3970a299e3085b3483a5330e3a06b8e8.jpg",
        //     name: "xibei.jpg",
        //     uid: 1705661529468,
        //   },
        // ],
        username: "",
        nickname: "",
        mobile: "",
        email: "",
        gender: "",
      },
      formConfig: {
        labelWidth: this.$FontSize(1) + "px",
        rules: {
          avatar1: [
            {
              required: true,
              message: "请上传图像",
              trigger: "blur",
            },
          ],
          username: [
            {
              required: true,
              message: "请输入用户名",
              trigger: "blur",
            },
            {
              pattern: /^[0-9a-zA-Z]{5,12}$/,
              message: "请输入5-12位的英文或数字",
              trigger: "blur",
            },
            // { validator: validateName, trigger: "blur" }, // 验证器，触发方式为失去焦点
          ],
          nickname: [
            {
              required: true,
              message: "请输入昵称",
              trigger: "blur",
            },
          ],
          mobile: [
            {
              required: true,
              message: "请输入手机号",
              trigger: "blur",
            },
            {
              // pattern: /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/, //座机或者手机验证
              //手机号验证
              pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的号码格式",
              trigger: "blur",
            },
          ],
          email: [
            {
              required: false,
              message: "请输入邮箱",
              trigger: "blur",
            },
            {
              pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              message: "请输入正确的邮箱格式",
              trigger: "blur",
            },
          ],
          gender: [
            {
              required: true,
              message: "请选择性别",
              trigger: "blur",
            },
          ],
          password: [
            {
              required: false,
              message: "请输入密码",
              trigger: "blur",
            },
            {
              pattern: /^[0-9a-zA-Z]{5,12}$/,
              message: "请输入5-12位的英文或数字",
              trigger: "blur",
            },
          ],
        },
      },
      formData: [
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "头像",
                prop: "avatar1",
              },
              type: "component",
              key: "avatar1",
              itemProps: {
                multiple: true, // 是否多选
                // showFileList: true, // 是否显示文件列表
                // accept: "image/*", // 限制上次文件类型
                accept: ".jpg,.png", // 限制上次文件类型
                class: "user-avatar-upload",
                limit: 1, // 限制文件个数
                // listType: "picture-card", // 文件列表展示样子
              },
              componentName: "Upload",
              componentKey: "avatar1",
              showUploadBtn: true, // 是否显示上传按钮
              showImage: true, // 是否显示图片
              fileList: [], // 文件列表
              itemEvents: {},
              defaultData: [],
            },
          ],
        },
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "用户名",
                prop: "username",
              },
              type: "el-input",
              key: "username",
              itemProps: {
                placeholder: "请输入用户名",
                //只读
                readonly: true,
                disabled: true,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "昵称",
                prop: "nickname",
              },
              type: "el-input",
              key: "nickname",
              itemProps: {
                placeholder: "请输入昵称",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "手机号",
                prop: "mobile",
              },
              type: "el-input",
              key: "mobile",
              itemProps: {
                placeholder: "请输入手机号",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            //邮箱

            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "邮箱",
                prop: "email",
              },
              type: "el-input",
              key: "email",
              itemProps: {
                placeholder: "请输入邮箱",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            //性别

            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "性别",
                prop: "gender",
              },
              type: "el-select",
              key: "gender",
              itemProps: {
                placeholder: "请选择性别",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
              options: [
                {
                  label: "男",
                  value: 1,
                },
                {
                  label: "女",
                  value: 2,
                },
              ],
            },

            {
              // hidden: this.row ? true : false,
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "密码",
                prop: "password",
              },
              type: "el-input",
              key: "password",
              itemProps: {
                placeholder: "修改密码时请输入",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
          ],
        },
      ],
      showTable: false,
      // userInfo: JSON.parse(localStorage.getItem("userInfo"))
      salt: "",
    };
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      // if (this.row) {
      //   this.form = JSON.parse(JSON.stringify(this.row));
      // } else {
      // }
      userInfo()
        .then((res) => {
          // this.$nextTick(() => {
          if (res.data.avatar1) {
            this.form.avatar1 = res.data.avatar1;
          }
          this.form.username = res.data.username;
          this.form.nickname = res.data.nickname;
          this.form.mobile = res.data.mobile;
          this.salt = res.data.salt;

          this.form.email = res.data.email;
          this.form.gender = res.data.gender;
          // });
        })
        .finally((res) => {
          this.showTable = true;
        });
    },
    initFormKey() {
      this.formData.forEach((item) => {
        item.cols.forEach((col) => {
          this.$set(this.form, col.key, "");
        });
      });
    },
    confirm() {
      this.$refs.GlobalForm.$refs.form.validate((valid) => {
        if (valid) {
          this.form.avatar1 = JSON.stringify(this.form.avatar1);
          this.form.salt = this.salt;
          profile(this.form).then((res) => {
            const _this = this;
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1000,
              onClose() {
                // _this.getData();
                _this.$router.push("/login");
              },
            });
          });
        }
      });
    },
    close() {
      this.row ? this.$emit("onUserClose") : this.$emit("onUserClose");
    },
    cascaderChange() {
      console.log("触发了");
      this.$refs.GlobalForm.$refs.form.validateField("all");
      // this.$refs.form.validateField(fieldKey); // 手动触发fileList校验规则
    },
  },
};
</script>
  
  <style lang="scss">
.join-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .confirm-btn {
    width: 100%;
  }

  // .small-dialog-box {
  .el-dialog {
    height: px2vh(800) !important;
  }
  // }
}
</style>
  