<template>
  <el-form
    class="custom-search-form"
    v-bind="formConfig.props || {}"
    v-on="formConfig.events || {}"
    :model="form"
  >
    <el-form-item
      v-bind="col.formItemProps || {}"
      v-for="(col, index) in formData"
      :key="index"
    >
      <el-select
        v-if="col.type === 'el-select-tree'"
        :ref="`select_${col.key}`"
        popper-class="custom-select-tree"
        :style="{ width: selectOptionWidth }"
        v-model="form[col.key]"
        v-bind="col.itemProps || {}"
        v-on="col.itemEvents || {}"
        @focus="setOptionWidth"
      >
        <el-option :value="form[col.key]" :label="col.treeLabel">
          <el-tree
            :ref="`select_tree_${col.key}`"
            v-bind="col.treeProps"
            @node-click="handleNodeClick($event, col)"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <GlobalAutoTooltip :content="node.label" placement="right" />
            </span>
          </el-tree>
        </el-option>
      </el-select>
      <el-select
        v-else-if="col.type === 'el-select'"
        v-model="form[col.key]"
        v-bind="col.itemProps || {}"
        v-on="col.itemEvents || {}"
        @focus="setOptionWidth"
      >
        <el-option
          :label="option[col.labelKey || 'label']"
          :value="option[col.valueKey || 'value']"
          v-for="option in col.options"
          :key="option[col.valueKey || 'value']"
        ></el-option>
      </el-select>
      <el-input
        v-else-if="col.type === 'el-input'"
        v-model="form[col.key]"
        v-bind="col.itemProps || {}"
        v-on="col.itemEvents || {}"
      ></el-input>

      <el-date-picker
        v-else-if="col.type === 'el-date-picker'"
        v-model="form[col.key]"
        v-bind="col.itemProps || {}"
        v-on="col.itemEvents || {}"
      >
      </el-date-picker>

      <el-button
        v-waves="{ className: 'waves-light' }"
        v-else-if="col.type === 'el-button'"
        v-bind="col.itemProps || {}"
        v-on="col.itemEvents || {}"
        >{{ col.itemProps.buttonName }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    formConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    formData: {
      type: Array,
      default() {
        return [];
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectOptionWidth: "auto",
    };
  },
  methods: {
    setOptionWidth(event) {
      // 下拉框弹出时，设置弹框的宽度
      this.$nextTick(() => {
        this.selectOptionWidth = event.srcElement.offsetWidth + "px";
      });
    },
    handleNodeClick(data, col) {
      this.form[col.key] = data.id;
      col.treeLabel = data[col.treeProps.props.label];
      this.$refs[`select_${col.key}`][0].blur();
    },
  },
};
</script>

<style lang="scss">
.custom-search-form {
  $lineHeight: px2vh(40);
  position: relative;
  // @include flex(row, normal, normal);
  // flex-wrap: wrap;
  .el-form-item {
    // @include flex(column, flex-end, normal);
    margin-right: px2vh(20);
    margin-bottom: px2vh(20);
  }

  .el-form-item__label {
    @include font4vh(rgba(203, 224, 255, 1), 14, 400);
    line-height: $lineHeight;
    // padding-bottom: 0;
    padding-right: px2vh(10);
  }

  .el-form-item__content {
    margin-top: 0;
    line-height: normal;
    height: $lineHeight;
    .el-select,
    .el-date-editor.el-input,
    .el-date-editor--daterange.el-input__inner {
      height: 100%;
    }

    .el-input {
      width: px2vw(190);
      height: 100%;
      .el-input__inner {
        height: 100%;
        line-height: $lineHeight;
        background-color: transparent;
        border-color: rgba(203, 224, 255, 0.5);
        // border-radius: px2vh(4);
        @include font4vh(#fff, 14, 400);

        &::placeholder {
          color: rgba(203, 224, 255, 1);
        }
      }
    }

    .el-input__icon {
      line-height: $lineHeight;
    }

    .el-date-editor--daterange.el-input__inner {
      width: px2vw(270);
      padding: 0;
      line-height: $lineHeight;
      background: rgba(14, 79, 117, 1);
      // border: 1px solid #21a69a;
      border: none;
      @include font4vh(rgba(168, 214, 255, 1), 14, 400);
    }

    .el-date-editor .el-range-separator {
      @include font4vh(rgba(168, 214, 255, 1), 14, 400);
      line-height: px2vh(38);
    }

    .el-range-input {
      background: transparent;
      @include font4vh(rgba(168, 214, 255, 1), 14, 400);
    }

    .el-date-editor .el-range__icon {
      line-height: px2vh(38);
      margin-left: px2vh(10);
    }

    .el-button {
      border: none;
      line-height: normal;
      padding: 0;
      width: px2vw(100);
      height: 100%;
      background: rgba(10, 26, 52, 0.6);
      border-radius: px2vh(50);
      border: px2vh(1) solid rgba(89, 175, 249, 0.71);
      @include flex(row, center, center);
      @include font4vh(#01c2ff, 16, 400);

      &.search {
      }
      &.reset {
        border: 1px solid rgba(34, 115, 206, 1);
      }
      &.export {
        margin-top: px2vh(20);
        background: #03b28e;
      }
      &.add {
        width: px2vw(110);
        margin-top: px2vh(20);
        background: #03b28e;
      }
    }
  }
}
</style>
