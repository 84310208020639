import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    themeClass: localStorage.getItem("themeClass"),
  },
  getters: {},
  mutations: {
    // 设置主题
    setTheme(state, payload) {
      localStorage.setItem("themeClass", payload);
      state.themeClass = payload;
    },
    // 切换语言
    changeLanguage(state, payload) {
      localStorage.setItem("lanague", payload); // 这样页面刷新就不会改变选择的语言了
      this.$i18n.locale = payload;
    },
  },
  actions: {},
  modules: {},
});
