<template>
  <!-- 预定会议 -->
  <el-dialog
    :class="[
      'custom-dialog-box',
      'join-meeting-dialog-box',
      'meeting-invites-dialog-box',
      options.size,
    ]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal="false"
    :modal-append-to-body="false"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <div class="share-box">
      <div class="item-box">
        <div class="key-box">会议名称：</div>
        <div class="value-box">{{ row.quickMeetingRoomTitle }}</div>
      </div>
      <div class="item-box">
        <div class="key-box">会议时间：</div>
        <div class="value-box">{{ row.time }}</div>
      </div>
      <div class="item-box">
        <div class="key-box">会议号码：</div>
        <div class="value-box">
          {{ row.quickMeetingRoomNumber }}
          <span
            class="copy-btn"
            @click="handleClip(row.quickMeetingRoomNumber, $event)"
            style="color: rgb(64, 158, 255); cursor: pointer; margin-left: 20px"
            >复制</span
          >
        </div>
      </div>
    </div>
    <GlobalForm
      ref="GlobalForm"
      class="invites-form-box hide-scrollbar"
      :formConfig="formConfig"
      :formData="formData"
      :form="form"
    >
    </GlobalForm>
    <span slot="footer" class="dialog-footer">
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >邀请</el-button
      >
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>
  
  <script>
import clipboard from "@/utils/clipboard";
import { shuList1, invite } from "@/api/home.js";
export default {
  mixins: [],
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      // group_id: JSON.parse(localStorage.getItem("userInfo")).group_id,
      formDataGroup: null,
      form: {
        invite_ids: [],
      },
      formConfig: {
        labelPosition: "left",
        labelWidth: this.$FontSize(1.3) + "px",
        rules: {
          invite_ids: [
            {
              required: true,
              message: "请选择邀请参会人员",
              trigger: "blur",
            },
          ],
        },
      },
      formData: [
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "邀请参会人员",
                prop: "invite_ids",
              },
              type: "el-cascader",
              key: "invite_ids",
              itemProps: {
                placeholder: "请选择邀请参会人员",
                // disabled: this.options.disabled,
                multiple: true, //多选
                // showAllLevels: false,
                emitPath: false,
              },
              labelKey: "name",
              valueKey: "id",
              options: [],
              itemEvents: {
                change: this.cascaderChange,
              },
            },
          ],
        },
      ],
    };
  },
  watch: {},
  created() {
    console.log("this.row===============", this.row);
    this.init();
  },
  methods: {
    init() {
      this.getTreeList();
      if (this.row) {
        this.form = JSON.parse(JSON.stringify(this.row));
      }
    },
    getTreeList() {
      shuList1().then((res) => {
        this.formData[0].cols[0].options = res.data;
      });
    },
    confirm() {
      this.$refs.GlobalForm.$refs.form.validate((valid) => {
        if (valid) {
          const js_user_ids = this.form.invite_ids.join();

          //调分享接口
          const params = {
            //会议名称
            // name: this.row && this.row.quickMeetingRoomTitle,
            // time: this.row && this.row.start_time,
            meeting_code: this.row && this.row.quickMeetingRoomNumber,
            js_user_ids: js_user_ids,
          };
          console.log(
            "🚀 ~ this.$refs.GlobalForm.$refs.form.validate ~ params:",
            params
          );
          invite(params).then((res) => {
            console.log("🚀 ~ invite ~ res:", res);
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.options.visible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      //
      this.options.visible = false;
    },
    cascaderChange() {
      console.log("触发了");
      this.$refs.GlobalForm.$refs.form.validateField("all");
      // this.$refs.form.validateField(fieldKey); // 手动触发fileList校验规则
    },
    handleClip(text, event) {
      clipboard(text, event);
      event.stopPropagation();
    },
  },
};
</script>
  
  <style lang="scss">
.meeting-invites-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .el-dialog__body {
    background: #0a1a34 !important;
    padding: 20px !important;
    box-sizing: border-box;

    .invites-form-box {
      padding: 20px;
      box-sizing: border-box;
    }
  }
  .share-box {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
    font-size: 0.16rem;

    .item-box {
      margin: px2vh(20);
      display: flex;
      align-items: center;

      .key-box {
        padding: px2vh(10) px2vh(30);
      }

      .value-box {
        user-select: none;

        .copy-btn {
          cursor: pointer;
        }
      }
    }
  }

  .confirm-btn {
    width: 100%;
  }
}
</style>
  