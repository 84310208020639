<template>
  <!-- 反馈意见 -->
  <el-dialog
    :class="['custom-dialog-box', 'join-meeting-dialog-box', options.size]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <GlobalForm
      ref="GlobalForm"
      class="h100 info-form-box hide-scrollbar"
      :formConfig="formConfig"
      :formData="formData"
      :form="form"
    >
    </GlobalForm>
    <span slot="footer" class="dialog-footer">
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >提交</el-button
      >
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>
  
  <script>
import { joinMeeting } from "@/api/home.js";
export default {
  mixins: [],
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      // group_id: JSON.parse(localStorage.getItem("userInfo")).group_id,
      formDataGroup: null,
      form: {
        title: "",
        content: "",
        phone: "",
        avatar1: [],
      },
      formConfig: {
        labelPosition: "top",
        labelWidth: this.$FontSize(1) + "px",
        rules: {
          title: [
            {
              required: true,
              message: "请输入标题",
              trigger: "blur",
            },
          ],
          content: [
            {
              required: true,
              message: "请输入反馈内容",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "请输入联系电话",
              trigger: "blur",
            },
            {
              //手机号验证
              pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的号码格式",
              trigger: "blur",
            },
          ],
          avatar1: [
            {
              required: true,
              message: "请上传图片",
              trigger: "blur",
            },
          ],
        },
      },
      formData: [
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "标题",
                prop: "title",
              },
              type: "el-input",
              key: "title",
              itemProps: {
                placeholder: "请输入标题",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "反馈内容",
                prop: "content",
              },
              type: "el-input",
              key: "content",
              itemProps: {
                placeholder: "请输入反馈内容",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "联系电话",
                prop: "phone",
              },
              type: "el-input",
              key: "phone",
              itemProps: {
                placeholder: "请输入联系电话",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "图片",
                prop: "avatar1",
              },
              type: "component",
              key: "avatar1",
              itemProps: {
                multiple: false, // 是否多选
                // showFileList: true, // 是否显示文件列表
                // accept: "image/*", // 限制上次文件类型
                accept: ".jpg,.png", // 限制上次文件类型
                class: "user-avatar-upload",
                // limit: 1, // 限制文件个数
                // listType: "picture-card", // 文件列表展示样子
              },
              componentName: "Upload",
              componentKey: "avatar1",
              showUploadBtn: true, // 是否显示上传按钮
              showImage: true, // 是否显示图片
              fileList: [], // 文件列表
              itemEvents: {},
              defaultData: [],
            },
          ],
        },
      ],
    };
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.row) {
        // this.form = JSON.parse(JSON.stringify(this.row));
      } else {
      }
    },
    initFormKey() {
      this.formData.forEach((item) => {
        item.cols.forEach((col) => {
          this.$set(this.form, col.key, "");
        });
      });
    },
    confirm() {
      this.$refs.GlobalForm.$refs.form.validate((valid) => {
        if (valid) {
          // this.row
          //   ? this.$emit("onEditClose", this.form)
          //   : this.$emit("onJoinClose", this.form);
          this.$emit("onJoinClose", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("onJoinClose");
    },
  },
};
</script>
  
  <style lang="scss">
.join-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .confirm-btn {
    // width: 100%;
  }
}
</style>
  