<template>
  <div class="main-layout-box h100">
    <div class="main-header-box">
      <div class="left">
        <!-- <img src="@/assets/login/logo.png" alt="" /> -->
        <img :src="loginInfo.logo" alt="" />
        <!-- <span class="line">|</span> -->
        <!-- <span>远程视频协作会商系统</span> -->
        <!-- <span>{{ loginInfo.name }}</span> -->
      </div>
      <div class="right">
        <div @click="get_message_reminder()">
          <!-- :is-dot="redDot" 红点 -->
          <el-badge
            :value="messageNumber"
            :max="9"
            slot="reference"
            class="badge-box"
            :hidden="messageNumber == 0"
          >
            <img
              class="msg-icon"
              id="topImg"
              src="@/assets/home/icon_news.png"
              alt=""
            />
          </el-badge>
        </div>
        <el-popover
          placement="bottom"
          popper-class="notify-popper nickname-notify-popper"
          trigger="click"
        >
          <div class="user-item" @click="editUserInfo">个人资料</div>
          <el-divider></el-divider>
          <div class="user-item user-quit" @click="quit">退出登录</div>

          <div slot="reference" class="user-info-box" @click="clickUserInfo">
            <!-- <img class="user-img" src="@/assets/home/avatar.png" alt="" /> -->
            <img class="user-img" :src="$baseUrl + userImg" alt="" />
            <span>{{ nickname || "" }}</span>
            <img
              :class="[rotateClass, 'down']"
              src="@/assets/home/chevron_down.png"
              alt=""
            />
          </div>
        </el-popover>
        <span style="width: 20px"> </span>

        <!-- 下载APP -->
        <el-popover
          placement="bottom"
          popper-class="notify-popper link-popper"
          trigger="click"
        >
          <div style="text-align: center">
            <div>
              <a :href="appDownloadUrl" download="meetingAPP.apk">下载APP</a>
              <span
                class="copy-btn"
                @click="handleClip(appDownloadUrl, $event)"
                style="
                  color: rgb(64, 158, 255);
                  cursor: pointer;
                  margin-left: 20px;
                "
                >复制链接</span
              >
            </div>
            <el-divider></el-divider>
            <div>
              <a :href="pcDownloadUrl" download="meetingPC.zip">下载PC端</a>
              <span
                class="copy-btn"
                @click="handleClip(pcDownloadUrl, $event)"
                style="
                  color: rgb(64, 158, 255);
                  cursor: pointer;
                  margin-left: 20px;
                "
                >复制链接</span
              >
            </div>
          </div>
          <div slot="reference" class="user-info-box">
            <img
              style="width: 20px; height: 20px"
              src="@/assets/home/xiazai.png"
              alt=""
            />
          </div>
        </el-popover>

        <!-- 反馈 -->
        <div
          style="margin-left: 20px; font-size: 12px; cursor: pointer"
          class="feedback-box"
          @click="onFeedback"
        >
          反馈
        </div>
      </div>
    </div>
    <!-- <div class="main-layout-left">
      <Menu />
    </div> -->
    <div class="main-body-box">
      <transition
        enter-active-class="fadeInLeft"
        leave-active-class="fadeOutLeft"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </div>
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <UserInfo
        v-if="userInfodialogOptions.visible"
        :options="userInfodialogOptions"
        @onUserClose="onUserClose"
      />
      <!-- @onEditClose="onEditClose" -->
    </transition>
    <!-- 快速会议 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <QuickMeeting
        v-if="QuickMeetingdialogOptions.visible"
        :options="QuickMeetingdialogOptions"
        :QuickMeetingProps="QuickMeetingProps"
        @onKSClose="onKSClose"
      />
      <!-- :quickMeetingRoomNumber="quickMeetingRoomNumber"
        :quickMeetingRoomTitle="quickMeetingRoomTitle" -->
    </transition>
    <!-- 反馈 -->
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
      <Feedback
        v-if="FeedbackdialogOptions.visible"
        :options="FeedbackdialogOptions"
        @onJoinClose="onFeedbackClose"
      />
    </transition>
  </div>
</template>

<script>
import clipboard from "@/utils/clipboard";
import UserInfo from "./components/UserInfo.vue";
import Menu from "./components/Menu.vue";
import QuickMeeting from "../Home/components/QuickMeeting.vue";
import Feedback from "./components/Feedback.vue";
import {
  unreadMessage, //当前未读取消息,
  meetingInvite, //会议邀请弹窗
  sumList,
  messageStatus, //消息已读操作
  addFeedBack, // 添加反馈
} from "@/api/home.js";
import { download } from "@/api/user.js";

import { set } from "vue";
export default {
  components: {
    Menu,
    UserInfo,
    QuickMeeting, //快速会议
    Feedback, //反馈
  },
  data() {
    return {
      loginInfo: JSON.parse(localStorage.getItem("loginInfo")),
      nickname: JSON.parse(localStorage.getItem("userInfo")).nickname,
      userImg: JSON.parse(localStorage.getItem("userInfo")).avatar1,
      redDot: true,
      isRotated: false,
      // 显示个人信息弹窗
      userInfodialogOptions: {
        title: "个人信息编辑",
        visible: false,
        disabled: false,
        // size: "small-dialog-box",
        size: "small-dialog-box",
      },
      // 未读消息
      messageNumber: "",
      // 消息数量
      timer: null,
      //消息弹窗
      invitesTimer: null,
      //最新会议信息
      newMeetinginfo: null,
      // 显示快速会议弹窗
      QuickMeetingdialogOptions: {
        title: "",
        visible: false,
        disabled: false,
        // size: "small-dialog-box",
      },
      QuickMeetingProps: {
        // 快速会议房间号
        quickMeetingRoomNumber: "",
        //快速会议房间名称
        quickMeetingRoomTitle: "",
        // 昵称
        nickname: "",
        //是否开启摄像头
        is_camera: false,
        //是否开启麦克风
        is_speech: false,
        //是否开启等候室
        waiting_room: false,
      },
      // 是否在会议中
      isMeeting: false,
      //是否页面销毁
      isDestroy: false,

      //app下载地址
      appDownloadUrl: "",
      //PC端下载地址
      pcDownloadUrl: "",

      // 显示加入会议弹窗
      FeedbackdialogOptions: {
        title: "反馈",
        visible: false,
        disabled: false,
        size: "small-dialog-box",
      },
    };
  },
  computed: {
    rotateClass() {
      return this.isRotated ? "rotate" : "";
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    // this.isDestroy = true;
    this.timer && clearInterval(this.timer);
    // this.$notify.closeAll();
    if (this.inviteNotify && !this.inviteNotify.closed) {
      this.inviteNotify.close();
    }
  },
  methods: {
    init() {
      //获取消息和邀请信息
      this.getSumList();

      this.timer = setInterval(() => {
        this.getSumList();
        console.log(
          "🚀 ~ this.timer=setInterval ~ this.inviteNotify:",
          this.inviteNotify
        );
      }, window.g.intervalTime || 3000);
      //下载地址
      download().then((res) => {
        console.log("🚀 ~ download ~ res:", res);
        this.appDownloadUrl = res.data.app_url;
        this.pcDownloadUrl = res.data.pc_url;
      });
    },
    getSumList() {
      sumList().then((res) => {
        // 未读消息数量
        this.messageNumber = res.data.unreadMessage;
        if (this.inviteNotify && !this.inviteNotify.closed) return;
        if (res.data.meetingInvite && !this.isMeeting) {
          // 有邀请信息
          // this.clearInvitesTimer();
          this.newMeetinginfo = res.data.meetingInvite;
          const h = this.$createElement;
          this.inviteNotify = this.$notify({
            title: "",
            dangerouslyUseHTMLString: true,
            customClass: "warning-alert-box",
            type: "",
            position: "bottom-right",
            duration: 0,
            message: h(
              "div",
              {
                style: {
                  display: "flex",
                  marginLeft: "10px",
                  "flex-direction": "column",
                  alignItems: "flex-start",
                },
              },
              [
                // h("img", {
                //   class: "info",
                //   attrs: {
                //     width: "100",
                //     height: "80",
                //     src: require("@/assets/images/common/animate_alert4.gif"),
                //   },
                // }),
                h(
                  "div",
                  {
                    style: {
                      flex: "1",
                      marginLeft: "0px",
                      fontSize: "16px",
                      marginLeft: "-10px",
                      fontWeight: "600",
                      marginTop: "10px",
                      marginBottom: "10px",
                      color: "#000",
                    },
                  },
                  // ["会议邀请消息"]
                  [this.newMeetinginfo["content"]]
                ),
                h(
                  "div",
                  {
                    style: {
                      flex: "1",
                      marginLeft: "0px",
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: "5px",
                      color: "#606266",
                    },
                  },
                  ["会议名称：" + this.newMeetinginfo["meeting_title"]]
                ),
                h(
                  "div",
                  {
                    style: {
                      flex: "1",
                      marginLeft: "0px",
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: "5px",
                      color: "#606266",
                    },
                  },
                  ["会议时间：" + this.newMeetinginfo["meeting_start_time"]]
                ),
                h(
                  "div",
                  {
                    style: {
                      flex: "1",
                      marginLeft: "0px",
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: "5px",
                      color: "#606266",
                    },
                  },
                  ["发起人：" + this.newMeetinginfo["fq_user_name"]]
                ),
                h(
                  "div",
                  {
                    style: {
                      textAlign: "right",
                      width: "100%",
                    },
                  },
                  [
                    h(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: this.joinMeeting.bind("222"),
                        },
                        style: {
                          "text-align": "right",
                          marginTop: "20px",
                        },
                      },
                      "加入会议"
                    ),
                  ]
                ),
                // h("div", {
                //   class: "detail",
                // }),
                // h(
                //   "el-button",
                //   {
                //     attrs: {
                //       type: "primary",
                //       size: "mini",
                //     },
                //     on: {
                //       click: this.joinMeeting.bind("222"),
                //     },
                //     style: {
                //       "text-align": "right",
                //       marginTop: "20px",
                //     },
                //   },
                //   "加入会议"
                // ),
              ]
            ),
            onClose: () => {
              this.closeMessage();
            },
          });
        }
      });
    },
    get_message_reminder() {
      this.$router.push("/main-layout/meeting-message");
      // alert("查看消息");
    },
    //关闭消息提醒,标记已读
    closeMessage() {
      const params = {
        id: this.newMeetinginfo.id,
      };
      messageStatus(params).then((res) => {
        // console.log("🚀 ~ messageStatus ~ res:", res);
      });
      //定时请求
      // if (!this.isMeeting && !this.isDestroy) {
      //   setTimeout(() => {
      //     this.init();
      //   }, 1000);
      //   console.log("不在会议中开启定时请求");
      // }
    },
    // 加入会议
    joinMeeting() {
      //关闭弹窗
      this.isMeeting = true;
      if (this.newMeetinginfo) {
        this.QuickMeetingProps.quickMeetingRoomNumber =
          this.newMeetinginfo.number;
        this.QuickMeetingProps.quickMeetingRoomTitle =
          this.newMeetinginfo.meeting_title;
        this.QuickMeetingProps.start_time = this.$Moment(
          this.newMeetinginfo.meeting_start_time
        ).format("yyyy-MM-DD HH:mm");
        this.QuickMeetingProps.nickname = this.nickname;
        this.QuickMeetingProps.is_camera = false;
        this.QuickMeetingProps.is_speech = false;
        this.QuickMeetingdialogOptions.visible = true;
      }

      if (this.inviteNotify && !this.inviteNotify.closed) {
        this.inviteNotify.close();
      }
      // this.$notify.closeAll();
      // this.closeMessage()
    },
    onKSClose() {
      this.QuickMeetingdialogOptions.visible = false;
      this.isMeeting = false;
      this.init();
    },
    // 点击图像
    clickUserInfo() {
      this.isRotated = !this.isRotated;
    },
    // 修改个人信息
    editUserInfo() {
      this.userInfodialogOptions.visible = true;
    },
    onUserClose() {
      this.userInfodialogOptions.visible = false;
    },
    // 退出
    quit() {
      //   this.$router.push("/login");
      const _this = this;
      this.$message({
        message: "已退出",
        type: "success",
        duration: 1000,
        onClose() {
          _this.$router.push("/login");
        },
      });
    },

    //复制链接
    handleClip(text, event) {
      clipboard(text, event);
      event.stopPropagation();
    },

    // 打开反馈‘
    onFeedback() {
      this.FeedbackdialogOptions.visible = true;
    },

    onFeedbackClose(form) {
      if (form) {
        let str = "";
        if (form.avatar1 && form.avatar1.length > 0) {
          // 把每一个里面的url,用，拼接起来
          var arr = form.avatar1.map((item) => {
            return item.url;
          });
          str = arr.join(",");
        }
        const params = {
          title: form.title,
          content: form.content,
          phone: form.phone,
          images: str,
        };
        console.log("🚀 ~ onFeedbackClose ~ form:", form);

        addFeedBack(params)
          .then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.FeedbackdialogOptions.visible = false;
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err.msg,
            });
          });
      } else {
        // this.currentRow = null;
        this.FeedbackdialogOptions.visible = false;
      }
    },
  },
};
</script>

<style lang="scss">
.main-layout-box {
  width: 100%;
  @include flex(column, flex-start, center);

  .main-header-box {
    width: 100%;
    height: px2vh(80);
    padding: 0 px2vh(20);
    @include flex(row, space-between, center);
    background: rgba(55, 65, 81, 0.5);

    .left {
      //   width: px2vw(420);
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        // width: px2vw(101);
        // height: px2vh(31);
        margin-left: 20px;
        width: px2vw(167);
        height: px2vh(34);
      }
      .line {
        margin: 0 px2vh(10);
      }
      span {
        @include font4vh(#fff, 22, 600);
      }
    }
    .right {
      @include flex(row, flex-start, center);
      //   禁止选择
      user-select: none;

      .badge-box {
        margin-right: px2vh(20);
        cursor: pointer;
      }

      .user-info-box {
        cursor: pointer;
        @include font4vh(#fff, 14, 400);
        .user-img {
          width: px2vh(40);
          height: px2vh(40);
          border-radius: 50%;
          margin: px2vh(20);
        }

        & span {
          margin-right: px2vh(10);
        }

        .down {
          width: px2vh(20);
          height: px2vh(20);
        }

        .rotate {
          transform: rotate(180deg);
        }
      }
    }
  }

  .main-body-box {
    width: 100%;
    height: 0;
    flex: 1;
    padding: 0.2rem;
    box-sizing: border-box;
  }
}
.notify-popper {
  .user-item {
    padding: px2vh(6) 0;
    font-size: 0.16rem;
    text-align: center;
    cursor: pointer;

    // &.user-quit {
    //   text-align: center;
    // }
  }
}

.link-popper {
  margin-left: 20px;
}
.el-divider--horizontal {
  margin: 10px 0;
  background-color: #ebeef5;
}
</style>
