<template>
  <div
    v-animate="{
      enterClass: 'animate__fadeIn',
      leaveClass: 'animate__fadeOut',
      leaveDelay: '200ms',
    }"
    class="register-box"
  >
    <!-- <el-select v-model="form.department_id" placeholder="请选择部门">
      <el-option label="部门一" value="shanghai"></el-option>
      <el-option label="部门二" value="beijing"></el-option>
    </el-select> -->
    <!-- v-animate="{
        enterClass: 'animate__bounceInRight',
        leaveClass: 'animate__bounceOutRight',
        enterDelay: '100ms',
        leaveDelay: '100ms',
      }" -->
    <div class="login-info">
      <div class="login-form-title">
        <!-- <img src="@/assets/login/logo.png" alt="" /> -->
        <img :src="loginInfo.logo" alt="" />
        <!-- <span class="line">|</span> -->
        <!-- <span>远程视频协作会商系统</span> -->
        <!-- <span>{{ loginInfo.name }}</span> -->
      </div>
      <el-form
        ref="form"
        :model="form"
        :rules="ruleForm"
        status-icon
        class="login-form"
        @submit.native.prevent
      >
        <el-form-item label="" prop="username">
          <div class="inputName">用户名</div>
          <el-input
            type="text"
            clearable
            v-model="form.username"
            placeholder="请输入用户名"
            autocomplete="off"
            class="input1"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="nickname">
          <div class="inputName">昵称</div>
          <el-input
            type="text"
            clearable
            v-model="form.nickname"
            placeholder="请输入昵称"
            autocomplete="off"
            class="input1"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="mobile">
          <div class="inputName">手机号</div>
          <el-input
            type="text"
            clearable
            v-model="form.mobile"
            placeholder="请输入手机号"
            autocomplete="off"
            class="input1"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="company_id">
          <div class="inputName">公司</div>
          <div>
            <el-select
              style="display: block"
              v-model="form.company_id"
              placeholder="请选择公司"
              @change="companyChange"
            >
              <el-option
                v-for="(item, index) in companyList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="" prop="department_id">
          <div class="inputName">部门</div>
          <el-select
            style="display: block"
            v-model="form.department_id"
            placeholder="请选择部门"
          >
            <el-option
              v-for="(item, index) in departmentList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="password">
          <div class="inputName">密码</div>
          <el-input
            :type="type"
            clearable
            v-model="form.password"
            auto-complete="off"
            placeholder="请输入密码"
            class="input1"
          >
            <i
              v-if="type === 'password'"
              slot="suffix"
              @click="changePasswordType"
              class="el-input__icon el-icon-lock"
            ></i>
            <i
              v-else
              slot="suffix"
              @click="changePasswordType"
              class="el-input__icon el-icon-unlock"
            >
            </i>
            ></el-input
          >
        </el-form-item>
        <el-form-item label="" prop="confirm_password">
          <div class="inputName">确认密码</div>
          <el-input
            :type="type1"
            clearable
            v-model="form.confirm_password"
            auto-complete="off"
            placeholder="请输入密码"
            class="input1"
          >
            <i
              v-if="type1 === 'password'"
              slot="suffix"
              @click="changeconfirm_passwordType"
              class="el-input__icon el-icon-lock"
            ></i>
            <i
              v-else
              slot="suffix"
              @click="changeconfirm_passwordType"
              class="el-input__icon el-icon-unlock"
            >
            </i>
            ></el-input
          >
        </el-form-item>
        <el-form-item>
          <div class="remember-container">
            <!-- <el-checkbox
              class="remember"
              v-model="remember"
              @change="rememberChange"
              >记住密码</el-checkbox
            > -->
            <!-- <div class="backend-btn">忘记密码?</div> -->
            <!-- <el-popover
                placement="right"
                width="220"
                popper-class="workface-popper"
              >
                <p v-if="loginInfo">{{ loginInfo.pwd_hint }}</p>
                <p>
                  <span>管理员：</span>
                  <span class="remember-phone">12333333333</span>
                </p>
                <div slot="reference" class="backend-btn">忘记密码?</div>
              </el-popover> -->
            <!-- <a class="backend-btn" href="javascript:;">后台管理</a> -->
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            v-waves="{ className: 'waves-light' }"
            class="login-btn"
            type="primary"
            @click="submit"
            :loading="logining"
            >立即注册</el-button
          >
        </el-form-item>
      </el-form>
      <div class="register-btn-box">
        等待审核通过后进行登录，<span class="register-btn" @click="OnRegister"
          >去登录</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { register, plistr, departments } from "@/api/user.js";
export default {
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.confirm_password !== "") {
          this.$refs.form.validateField("confirm_password");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        username: "",
        nickname: "",
        mobile: "",
        company_id: "",
        department_id: "",
        password: "",
        confirm_password: "",
      },
      loginInfo: JSON.parse(localStorage.getItem("loginInfo")),
      ruleForm: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            pattern: /^[0-9a-zA-Z]{5,12}$/,
            message: "请输入5-12位的英文或数字",
            trigger: "blur",
          },
        ],
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            // pattern: /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/, //座机或者手机验证
            //手机号验证
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "blur",
          },
        ],

        // email: [
        //   { required: true, message: "请输入邮箱", trigger: "blur" },
        //   {
        //     pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        //     message: "请输入正确的邮箱格式",
        //     trigger: "blur",
        //   },
        // ],
        //公司
        company_id: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        // 部门
        department_id: [
          { required: true, message: "请输入部门", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[0-9a-zA-Z]{5,12}$/,
            message: "请输入5-12位的英文或数字",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        confirm_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            pattern: /^[0-9a-zA-Z]{5,12}$/,
            message: "请输入5-12位的英文或数字",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      logining: false,
      type: "password",
      type1: "password",
      //公司列表
      companyList: [],
      //部门列表
      departmentList: [],
    };
  },
  created() {
    // document.addEventListener("keydown", this.onKeydown);
    this.init();
  },
  beforeDestroy() {
    // document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    init() {
      //获取公司
      plistr().then((res) => {
        console.log("🚀 ~ plistr ~ res:", res);
        this.companyList = res.data;
      });
    },

    companyChange(val) {
      console.log("🚀 ~ companyChange ~ val:", val);
      const params = {
        company_id: val,
      };
      this.form.department_id = "";
      //获取部门
      departments(params).then((res) => {
        console.log("🚀 ~ departments ~ res:", res);
        this.departmentList = res.data;
      });
    },
    // onKeydown(event) {
    //   if (event.keyCode == 13) {
    //     this.submit();
    //   }
    // },
    // rememberChange(val) {
    //   localStorage.setItem("isRemember", val);
    // },
    submit(event) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.logining = true;
          // this.$router.push("/main-layout/home");
          console.log("this.form,", this.form);
          register(this.form)
            .then((res) => {
              const _this = this;
              this.$message({
                message: "注册完成需审核通过后方可登录",
                type: "success",
                duration: 3000,
              });
            })
            .catch((err) => {})
            .finally(() => {
              this.logining = false;
            });
        } else {
          console.log("error submit!");
          return false;
        }
        console.log("🚀 ~ this.$refs.form.validate ~ this.form:", this.form);
        console.log("🚀 ~ this.$refs.form.validate ~ this.form:", this.form);
      });
    },
    changePasswordType() {
      this.type = this.type === "password" ? "text" : "password";
    },
    changeconfirm_passwordType() {
      this.type1 = this.type1 === "password" ? "text" : "password";
    },
    OnRegister() {
      // 跳转注册页面
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
.register-box {
  height: 100%;
  width: 100%;
  position: relative;
  @include flex(row, center, center);
  background: url("@/assets/login/video_BG1.png") no-repeat center center / 100%
    100%;

  .login-info {
    width: px2vw(645);
    height: 100%;
    overflow-y: scroll;
    // background: #040404;
    position: fixed;
    right: 0;
    top: 0;
    // padding: px2vh(344) px2vw(114);
    // box-sizing: border-box;
    @include flex(column, center, center);

    .login-form-title {
      width: px2vw(430);
      height: px2vh(56);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: px2vh(60);
      img {
        padding: 0 px2vh(20);
        width: px2vw(278);
        height: px2vh(56);
      }
      .line {
        margin: 0 px2vh(10);
      }
      span {
        @include font4vh(#fff, 26, 400);
      }
    }

    .login-form {
      width: px2vw(420);
      padding: 0 px2vh(20);
      box-sizing: border-box;

      .el-form-item {
        margin-bottom: 10px;
      }

      .inputName {
        @include font4vh(#fff, 16, 400);
        line-height: px2vh(28);
      }

      .remember-container {
        display: flex;
        justify-content: space-between;
        .backend-btn {
          // color: var(--clkd-color-global);
          font-family: OpenSans-Regular;
          font-size: 0.14rem;
          font-weight: normal;
          cursor: pointer;
          -webkit-user-select: none;
        }
      }

      .login-btn {
        width: 100%;
        height: px2vh(50);
        // background-color: var(--clkd-color-global);
        border: 0;
        @include font4vh(#fff, 14, 400);
      }

      .input1 {
        width: 100%;
        height: px2vh(50);
        margin-top: 0.07rem;
        .el-input__suffix {
          height: 0;
        }
      }
    }
  }

  .register-btn-box {
    margin-top: px2vh(20);
    font-size: 14px;
    .register-btn {
      color: #66b1ff;
      cursor: pointer;
    }
  }
}
</style>
