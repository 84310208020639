<template>
  <div class="global-table flex flex-col">
    <el-table
      class="global-table-container flex-1"
      :data="tableData"
      height="100%"
      v-bind="tableConfig"
      @selection-change="onSelectionChange"
      @sort-change="onSortChange"
      @row-click="onRowClick"
    >
      <!-- <el-table-column v-if="isSelection" type="selection" width="55">
      </el-table-column> -->
      <template v-for="item in tableColumn">
        <el-table-column
          :show-overflow-tooltip="item.showTooltip"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-if="!item.type"
          :key="`${item.prop}_${item.label}`"
          v-bind="{ align: 'center', headerAlign: 'center', ...item.colProp }"
          :sortable="item.sortable || false"
        >
          <template slot-scope="scope">
            <template v-if="item.showType === 'statusIcon'">
              <span :class="['status-icon', scope.row[item.key]]"></span>
            </template>
            <template v-else-if="item.showType === 'statusText'">
              <span
                :class="['status-text']"
                :style="{ color: scope.row[item.key] }"
                >{{ scope.row[item.prop] }}</span
              >
            </template>
            <template v-else-if="item.prop === 'custom-column'">
              <slot
                :name="item.name"
                :row="scope.row"
                :index="scope.$index"
              ></slot>
            </template>
            <template v-else-if="item.prop === 'handle'">
              <slot :row="scope.row" :index="scope.$index"></slot>
            </template>
            <template v-else>
              {{ scope.row[item.prop] }}
              <!-- <div v-bind="item.colProp || {}" style="height: 100%;">{{ scope.row[item.prop] }}</div> -->
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :type="item.type"
          :label="item.label || ''"
          :width="item.width"
          :key="item.prop"
          v-bind="{ align: 'center', headerAlign: 'center', ...item.colProp }"
        >
        </el-table-column>
      </template>
      <!-- <el-table-column label="操作" v-if="isOperate" :width="operateWidth">
        <template slot-scope="scope">
          <slot :row="scope.row" :index="scope.$index"></slot>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      v-if="showPagination"
      class="table-page-bottom mt-20"
      :background="true"
      @size-change="pagination.onSizeChange"
      @current-change="pagination.onCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    >
      <el-select
        v-if="
          pagination.layout.indexOf('slot') > -1 &&
          pagination.slotType == 'sizes'
        "
        size="mini"
        v-model="pagination.sizeValue"
        placeholder="请选择"
        @change="pagination.onSizeChange"
      >
        <el-option
          v-for="item in pagination.sizesOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    tableColumn: {
      type: Array,
      default() {
        return [];
      },
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    tableConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    pagination: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    onSelectionChange(selection) {
      this.$emit("onSelectionChange", selection);
    },
    onSortChange({ column, prop, order }) {
      this.$emit("onSortChange", prop, order);
    },
    onRowClick(row, column, event) {
      this.$emit("onRowClick", row, column, event);
    },
  },
};
</script>

<style lang="scss">
.global-table {
  height: 100%;
  .el-table {
    overflow: auto;
    background-color: transparent;
    .el-table__empty-text {
      font-size: px2vh(14);
    }
    .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0;
        height: px2vh(10);
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.3);
      }

      &::-webkit-scrollbar-corner {
        background-color: inherit !important;
      }
      // &::-webkit-scrollbar-track {
      //   border-radius: 10px;
      //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //   background-color: #555;
      // }
    }
    .el-table__header-wrapper {
      background: rgba(91, 160, 249, 0.1);
      border-bottom: px2vh(1) solid rgba(203, 224, 255, 1);
    }
    th.el-table__cell.is-leaf {
      border-bottom: 0;
    }
    .cell {
      font-size: 0.14rem;
      // text-align: center;
    }
    th {
      @include font4vh(rgba(66, 125, 220, 1), 14, 400);
      background-color: transparent;
      border: 0;
      padding: px2vh(10) 0;
    }
    tr {
      background-color: transparent;

      &:hover {
        td {
          // background: rgba($color: rgba(34, 115, 206, 1), $alpha: 0.3) !important;
          background: transparent !important;
        }
      }
    }
    td {
      @include font4vh(rgba(255, 255, 255, 0.85), 12, 400);
      border-bottom: px2vh(1) solid rgba(255, 255, 255, 0.1);
      padding: px2vh(10) 0;
    }
    &::before {
      height: 0px;
    }

    .el-table__body-wrapper {
      tr {
        &:hover {
          // background: url("../assets/images/dialog/list_checked@2x.png")
          //   no-repeat center center;
          // background-size: 100% 100%;
          background-color: rgba(91, 160, 249, 0.1);
          td {
            &:first-child {
              position: relative;
              &::before {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: px2vw(16);
                height: px2vh(16);
                content: "";
                // background: url("../assets/images/dialog/icon_arrow@2x.png")
                //   no-repeat center center;
                // background-size: 100% 100%;
              }
            }
          }
        }
      }
    }

    .status-icon {
      display: inline-block;
      width: px2vh(16);
      height: px2vh(16);
      border-radius: 50%;
      &.failed {
        background-color: #e45454;
      }
      &.success {
        background-color: #108c0a;
      }
    }
    .status-text {
      &.failed {
        color: #e45454;
      }
      &.success {
        color: #108c0a;
      }
    }
  }
  .table-page-bottom {
    @include font4vh(rgba(255, 255, 255, 0.85), 14, 400);
    flex: 0;
    text-align: right;
    .el-input__inner {
      background-color: transparent;
      height: 28px;
      border-color: rgba(66, 125, 220, 1);
    }
    .btn-prev {
      color: rgba(255, 255, 255, 0.85);
      background-color: transparent;
    }
    .number {
      color: rgba(255, 255, 255, 0.85);
      background-color: transparent;
    }
    .number.active {
      color: #fff !important;
      background-color: rgba(66, 125, 220, 1) !important;
    }
    .btn-next {
      color: rgba(255, 255, 255, 0.85);
      background-color: transparent;
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: rgba(255, 255, 255, 0.85);
    background-color: rgba(66, 125, 220, 1);
  }
  .el-pagination__sizes .el-input .el-input__inner:hover {
    border-color: rgba(66, 125, 220, 1);
  }

  .el-pagination {
    padding: 0;
  }
  .el-pagination__total {
    color: rgba(255, 255, 255, 0.85);
  }
  .el-input__inner {
    color: rgba(255, 255, 255, 0.85);
  }
  .el-pagination__jump {
    color: rgba(255, 255, 255, 0.85);
  }
}
</style>
