<template>
  <el-form class="custom-form-com" ref="form" :model="form" v-bind="formConfig">
    <el-row
      class="row-box"
      v-for="(row, index) in formData"
      :key="index"
      v-bind="row.rowProps"
    >
      <el-row v-bind="row.rowItemProps" class="row-content">
        <el-col v-for="col in row.cols" :key="col.key" v-bind="col.colProps">
          <el-form-item v-if="!col.hidden" v-bind="col.formItemProps">
            <!-- <slot v-if="col.type === 'slot'" :name="col.key" :row="row"></slot> -->
            <component
              v-if="col.type === 'component'"
              :is="col.componentName"
              v-bind="col"
              :col="col"
              :token="token"
              :form="form"
              @onValidateField="onValidateField"
            ></component>
            <el-cascader
              v-else-if="col.type === 'el-cascader'"
              v-model="form[col.key]"
              :options="col.options"
              :props="col.itemProps || {}"
              :show-all-levels="false"
              v-on="col.itemEvents || {}"
              clearable
            ></el-cascader>
            <!-- :show-all-levels="false" -->
            <!-- :props="{ multiple: true, emitPath: false, value: 'id', label: 'name' }" -->
            <el-select
              v-else-if="col.type === 'el-select-tree'"
              :ref="`select_${col.key}`"
              popper-class="custom-select-tree"
              :style="{ width: selectOptionWidth }"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              @focus="setOptionWidth"
            >
              <el-option :value="form[col.key]" :label="col.treeLabel">
                <el-tree
                  :ref="`select_tree_${col.key}`"
                  v-bind="col.treeProps"
                  @node-click="handleNodeClick($event, col)"
                >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <GlobalAutoTooltip
                      :content="node.label"
                      placement="right"
                    />
                  </span>
                </el-tree>
              </el-option>
            </el-select>
            <el-select
              v-else-if="col.type === 'el-select'"
              :style="{ width: selectOptionWidth }"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              @focus="setOptionWidth"
            >
              <el-option
                :label="option[col.labelKey || 'label']"
                :value="option[col.valueKey || 'value']"
                v-for="option in col.options"
                :key="option[col.valueKey || 'value']"
              ></el-option>
            </el-select>
            <el-checkbox
              v-else-if="col.type === 'el-checkbox'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
              >{{ col.name }}</el-checkbox
            >
            <el-input-number
              v-else-if="col.type === 'el-input-number'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            ></el-input-number>
            <el-input
              v-else-if="col.type === 'el-input'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            ></el-input>

            <el-image
              v-else-if="col.type === 'el-image'"
              :src="$baseUrl + form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            ></el-image>
            <el-date-picker
              v-else-if="col.type === 'el-date-picker'"
              v-model="form[col.key]"
              v-bind="col.itemProps || {}"
              v-on="col.itemEvents || {}"
            >
            </el-date-picker>
            <!-- <div v-else-if="col.type === 'text'">{{ form[col.key] }}</div> -->
            <div v-else-if="col.type === 'text'">{{ col.label }}</div>
            <a
              class="download-btn"
              v-else-if="col.type === 'download'"
              :download="form[col.key]"
              :href="form[col.key]"
              >下载</a
            >

            <div class="unit-box" v-if="col.unit" :name="col.key">
              {{ col.unit }}
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-row>
    <slot name="footer"></slot>
  </el-form>
</template>

<script>
import extendComponents from "../extend/index";

export default {
  components: {
    ...extendComponents,
  },
  // mixins: [extend],
  props: {
    formConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    formData: {
      type: Array,
      default() {
        return [];
      },
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectOptionWidth: "auto",
      token: "",
    };
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.token = userInfo.token;
    }
  },
  methods: {
    // 生成uid
    generateUid() {
      return Math.random().toString(18).substring(2, 10);
    },
    setOptionWidth(event) {
      // 下拉框弹出时，设置弹框的宽度
      this.$nextTick(() => {
        this.selectOptionWidth = event.srcElement.offsetWidth + "px";
      });
    },
    handleNodeClick(data, col) {
      this.$set(this.form, [col.key], data.id);
      col.treeLabel = data[col.treeProps.props.label];
      this.$refs[`select_${col.key}`][0].blur();
    },
    setDefaultUploadFiles(col) {
      const fileList = col.fileList.map((item) => {
        return {
          url: item,
        };
      });
      this.$refs[`upload_${col.key}`][0].uploadFiles = fileList;
    },
    beforeUpload(file, col) {
      if (col.itemProps.accept) {
        const ext = col.itemProps.accept;
        const fileExt = file.name.split(".").pop().toLowerCase();

        if (ext.indexOf(fileExt) == -1) {
          this.$message.warning(`请上传${ext}格式的文件`);
          return false;
        }
      }
    },
    handleSuccess(res, file, fileList, col) {
      // console.log(this.$refs.upload[0]);
      if (res.code == 1) {
        // col.fileList = fileList.map((item) => {
        //   return {
        //     url: item.url || item.response.data.url,
        //     name: item.name,
        //     uid: item.uid,
        //   };
        // });
        col.fileList = fileList.map((item) => {
          return {
            url: item.url || item.response.data.url,
            // name: item.name,
            // uid: item.uid,
          };
        });
        this.$set(
          this.form,
          [col.key],
          col.fileList.map((item) => item.url).join(",")
        );
        this.$refs.form.validateField(col.key); // 手动触发fileList校验规则

        //push 上传图片
        // col.fileList = fileList.map((item) => {
        //   return {
        //     url: item.url || item.response.data.url,
        //     name: item.name,
        //     uid: item.uid,
        //   };
        // });
        // this.$set(this.form, [col.key], col.fileList);
        // this.$set(this.form, [col.key], col.fileList.join(","));

        this.$refs.form.validateField(col.key); // 手动触发fileList校验规则
      } else {
        const index = fileList.findIndex((item) => item.uid == file.uid);
        fileList.splice(index, 1);
      }
      // this.$refs.upload[0].clearFiles();
      this.$message({
        message: res.msg,
        type: res.code == 1 ? "success" : "error",
        duration: 1500,
      });
    },
    onValidateField(fieldKey) {
      console.log(
        "🚀 ~ file: CustomForm.vue:328 ~ onValidateField ~ fieldKey:",
        fieldKey
      );
      this.$refs.form.validateField(fieldKey); // 手动触发fileList校验规则
    },

    handleError(err, col) {
      console.log(
        "🚀 ~ file: CustomForm.vue ~ line 179 ~ handleError ~ err, col",
        err,
        col
      );
      const error = JSON.parse(err.message);
      this.$refs[`upload_${col.key}`][0] &&
        this.$refs[`upload_${col.key}`][0].clearFiles();
      const that = this;
      that.$message({
        message: error.msg,
        type: "error",
        duration: 1500,
        // onClose: function() {
        //   that.$router.push('/login')
        // }
      });
    },
    download(index, item) {
      this.$utils.downloadByUrl(this.$baseUrl + item);
    },
    deleteUrl(index, col) {
      this.$refs[`upload_${col.key}`][0].uploadFiles.splice(index, 1);
      col.fileList.splice(index, 1);
      this.$set(this.form, [col.key], col.fileList.join(","));
    },
    onExceed(files, fileList, col) {
      this.$message.warning(
        `当前限制选择 ${col.itemProps.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
  },
};
</script>

<style lang="scss">
$baseHeight: 40;
.custom-form-com {
  .el-row--flex {
    flex-wrap: wrap;
  }

  .row-box {
    &:first-child {
      border-top: none;
    }
  }

  .custom-datetime {
    position: absolute !important;
    left: 0 !important;
    top: px2vh(40) !important;
  }

  .el-form-item {
    margin: px2vh(20) 0;

    // background-color: rgba(33, 166, 154, 0.1);
    padding: 0;
    box-sizing: border-box;
    border-radius: px2vh(4);
  }
  .el-form-item__label {
    @include font4vh(rgba(203, 224, 255, 1), 14, 400);
    height: px2vh($baseHeight);
    line-height: px2vh($baseHeight);
    padding-right: px2vh(10);
  }

  .el-form-item__content {
    line-height: px2vh($baseHeight);
    display: flex;
  }

  .el-checkbox {
    color: rgba(203, 224, 255, 1);

    .el-checkbox__label {
      color: rgba(203, 224, 255, 1);
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: transparent;
    border-color: rgba(203, 224, 255, 0.5);
  }

  .el-form-item__content {
    @include font4vh(#fff, 18, 400);

    .el-input__inner {
      border-radius: px2vh(4);
      // border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-color: rgba(203, 224, 255, 0.5);
      background-size: 100% 100%;
      @include font4vh(#fff, 14, 400);
      background-color: transparent;
      min-height: px2vh($baseHeight) !important;
      line-height: px2vh($baseHeight);

      &::placeholder {
        color: rgba(203, 224, 255, 1);
      }
    }

    .el-textarea {
      @include font4vh(#fff, 14, 400);
    }
    .el-textarea__inner {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      &::placeholder {
        @include font4vh(rgba(203, 224, 255, 1), 14, 400);
      }
    }

    .el-select .el-tag {
      padding: 0 px2vh(8);
      margin: px2vh(4);
      height: px2vh(28);
      line-height: px2vh(28);
    }

    .el-input__icon {
      line-height: px2vh($baseHeight);
    }
    .el-select,
    .el-date-editor.el-input,
    .el-date-editor--daterange.el-input__inner {
      width: 100% !important;
      // height: px2vh($baseHeight);
    }
    .el-date-editor--daterange.el-input__inner {
      padding: 0;
      border: none;
      @include font4vh(#fff, 14, 400);
    }

    .el-date-editor .el-range-separator {
      @include font4vh(#fff, 14, 400);
      line-height: px2vh($baseHeight);
    }

    .el-range-input {
      background-color: transparent;
      @include font4vh(#fff, 14, 400);
    }

    .el-select__input {
      @include font4vh(#fff, 14, 400);
    }

    .el-textarea__inner {
      @include font4vh(#fff, 14, 400);
      background-color: transparent;
      border-color: rgba(203, 224, 255, 0.5);
      &::-webkit-scrollbar {
        display: none !important; /* Chrome Safari */
      }
    }

    .el-input__count {
      background: transparent;
      bottom: 0;
      @include font4vh(rgba(168, 214, 255, 0.5), 14, 400);
    }

    .el-date-editor .el-range__icon {
    }
  }

  .has-unit {
    .el-input__inner {
      padding-right: px2vh(30);
    }
  }

  .has-unit2 {
    .el-input__inner {
      padding-right: px2vh(40);
    }
  }

  .download-btn {
    color: rgb(1, 194, 255);
    cursor: pointer;
  }

  .el-upload {
    @include flex(row, flex-start, center);
  }

  // .custom-upload {
  //   width: 100%;
  //   @include flex(row, flex-start, flex-start);
  // }

  .el-upload-list {
    display: none;
  }

  .el-upload-list {
    display: none;
  }

  .file-list-wrap {
    @include flex(row, flex-start, center);
    flex-wrap: wrap;

    // .file-list-item {
    //   height: px2vh($baseHeight);
    //   position: relative;
    //   z-index: 2;
    //   margin-left: 0.1rem;

    //   .upload-handle-icons {
    //     position: absolute;
    //     bottom: -0.2rem;
    //     left: 0;
    //     @include flex(row, flex-start, center);
    //   }
    // }

    .el-image__inner {
      width: px2vh($baseHeight);
      height: px2vh($baseHeight);
      object-fit: cover;
    }

    .upload-icon-item {
      font-size: px2vh($baseHeight);
    }

    .el-icon-download {
      color: #409eff;
      margin-right: 0.04rem;
    }

    .el-icon-delete {
      color: #f56c6c;
    }
  }

  .avatar {
    margin-left: px2vh(10);
    height: px2vh(40);
  }

  .unit-box {
    position: absolute;
    right: px2vh(10);
    top: 0;
    @include font4vh(rgba(203, 224, 255, 1), 14, 400);
  }
  .el-cascader {
    width: 100%;
  }
}
</style>
