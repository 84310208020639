<template>
  <!-- 快速会议 -->
  <!-- :title="options.title" -->
  <!-- :class="['custom-dialog-box', 'quick-meeting-dialog-box', options.size]" -->
  <el-dialog
    :class="['quick-meeting-dialog-box', options.size]"
    :visible.sync="options.visible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="true"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <div class="quick-box">
      <!-- <AppVideo :quickMeetingRoomNumber="quickMeetingRoomNumber" :quickMeetingRoomTitle="quickMeetingRoomTitle" /> -->
      <AppVideo :QuickMeetingProps="QuickMeetingProps" @onleave="close" />
    </div>
    <!-- <span slot="footer" class="dialog-footer" v-if="options.footerShow">
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >加入会议</el-button
      >
      <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button>
    </span> -->
  </el-dialog>
</template>
  
  <script>
import AppVideo from "@/AppVideo.vue";
import { likaiMeeting } from "@/api/home.js";
export default {
  components: {
    AppVideo,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    QuickMeetingProps: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
    // quickMeetingRoomNumber: {
    //   type: String,
    //   default() {
    //     return "";
    //   },
    // },
    // quickMeetingRoomTitle: {
    //   type: String,
    //   default() {
    //     return "";
    //   },
    // },
  },
  data() {
    return {
      user_id: JSON.parse(localStorage.getItem("userInfo")).id,
    };
  },
  watch: {},
  created() {
    console.log("房间信息", this.QuickMeetingProps);
    // this.init();
  },
  methods: {
    init() {},

    close() {
      console.log("🚀 ~============== close ~ close:")
      const params = {
        number: this.QuickMeetingProps.quickMeetingRoomNumber,
        user_id: this.user_id,
      };
      likaiMeeting(params)
        .then((res) => {
          console.log("🚀 ~ .then ~ res:离开会议", res);
          this.$message({
            message: res.msg,
            type: "success",
            duration: 1500,
          });
          // this.$emit("onKSClose");
        })
        .finally(() => {
          this.$emit("onKSClose");
        });
    },
    onleave() {
      // this.close()
      this.$emit("onKSClose");
      // this.options.visible = false;
    },
  },
};
</script>
  
  <style lang="scss">
.quick-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .close-btn-mask {
    width: px2vw(44);
    height: px2vh(26);
    cursor: pointer;
    position: absolute;
    right: px2vh(200);
    top: px2vh(10);
    @include flex(column, center, center);
    color: rgba(203, 224, 255, 1);
    font-size: px2vw(30);
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 100% !important;
    height: 100% !important;
    background: #000;
    padding: 0 !important;

    .quick-box {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
  