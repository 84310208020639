<template>
  <el-upload
    class="custom-upload"
    ref="upload"
    v-model="form[col.key]"
    v-bind="col.itemProps || {}"
    v-on="col.itemEvents || {}"
    :before-upload="(file) => beforeUpload(file, col)"
    :on-exceed="(file, fileList) => handleExceed(file, fileList, col)"
    :action="$baseUrl + '/api/common/upload?token=' + token"
    :on-error="($event) => handleError($event, col)"
    :on-success="
      ($event, file, fileList) => handleSuccess($event, file, fileList, col)
    "
  >
    <el-button
      v-if="col.showUploadBtn"
      size="mini"
      type="primary"
      v-bind="col.uploadBtnProps || {}"
      slot="trigger"
      >上传</el-button
    >
    <!-- <img
                v-if="col.showImage && form[col.key]"
                :src="$baseUrl + form[col.key]"
                class="avatar"
              /> -->
    <div class="file-list-wrap" v-if="col.fileList && col.fileList.length > 0">
      <span
        class="file-list-item"
        v-for="(item, index) in col.fileList"
        :key="item.url"
      >
        <!-- <el-image
          v-if="col.showImage"
          :src="$baseUrl + item.url"
          class="upload-image-item"
        >
        </el-image> -->

        <!-- 上传图片预览 -->
        <el-image
          v-if="col.showImage"
          fit="contain"
          :src="$baseUrl + col.fileList[index].url"
          :preview-src-list="col.fileList.map((item) => $baseUrl + item.url)"
        ></el-image>
        <div class="upload-file-item" v-else-if="col.showFiles">
          <i
            :class="[
              col.fileIconClass || 'el-icon-document',
              'upload-icon-item',
            ]"
          ></i>
          <GlobalAutoTooltip :content="item.name" placement="right" />
        </div>
        <span v-else class="flex-1 w0 ml-10" style="text-align: left"
          ><GlobalAutoTooltip :content="col.fileList" placement="right"
        /></span>
        <div class="upload-handle-icons">
          <i
            class="el-icon-download icon-pointer"
            @click="download(index, item)"
          ></i>
          <i
            class="el-icon-delete icon-pointer"
            @click="deleteUrl(index, col)"
          ></i>
        </div>
      </span>
    </div>

    <!-- <div slot="tip"
                 class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
  </el-upload>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      default: () => {},
    },
    col: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: "",
    };
  },
  created() {
    this.setDefaultUploadFiles();
  },
  mounted() {},
  methods: {
    setDefaultUploadFiles() {
      this.col.fileList = this.form[this.col.componentKey];
      this.$nextTick(() => {
        this.$refs.upload.uploadFiles = JSON.parse(
          JSON.stringify(this.col.fileList)
        );
      });
    },
    beforeUpload(file, col) {
      this.loading = this.$loading({
        lock: true,
        text: "上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (col.itemProps.accept) {
        const ext = col.itemProps.accept;
        const fileExt = file.name.split(".").pop().toLowerCase();

        if (ext.indexOf(fileExt) == -1) {
          this.$message.warning(`请上传${ext}格式的文件`);
          this.loading.close();
          return false;
        }
      }
    },
    handleSuccess(res, file, fileList, col) {
      this.loading.close();
      if (res.code == 1) {
        //push 上传图片
        col.fileList = fileList.map((item) => {
          console.log("🚀 ~ col.fileList=fileList.map ~ item:", item)
          return {
            url: item.url ||  item.response.data.url,
            name: item.name,
            uid: item.uid,
          };
        });
        // console.log(
        //   "🚀 ~ file: Upload.vue:117 ~ col.fileList=fileList.map ~ col.fileList:",
        //   col.fileList,
        //   this.$parent.$parent
        // );
        this.$set(this.form, [col.key], col.fileList);
        // this.$refs.Upload.validateField(col.key); // 手动触发fileList校验规则
        this.$emit("onValidateField", col.key);
      } else {
        const index = fileList.findIndex((item) => item.uid == file.uid);
        fileList.splice(index, 1);
      }
      // this.$refs.`upload`[0] && this.$refs.`upload`[0].clearFiles();
      this.$message({
        message: res.msg,
        type: res.code == 1 ? "success" : "error",
        duration: 1500,
      });
    },
    handleError(err, col) {
      this.loading && this.loading.close();
      const error = JSON.parse(err.message);
      this.$refs.upload.$refs.upload && this.$refs.upload.clearFiles();
      const that = this;
      that.$message({
        message: error.msg,
        type: "error",
        duration: 1500,
        // onClose: function() {
        //   that.$router.push('/login')
        // }
      });
    },
    download(index, item) {
      console.log("🚀 ~ file: Upload.vue:154 ~ download ~ item:", item);
      this.$utils.downloadByUrl(this.$baseUrl + item.url);
    },
    deleteUrl(index, col) {
      this.$refs.upload.uploadFiles.splice(index, 1);
      col.fileList.splice(index, 1);
      this.$set(this.form, [col.key], [...col.fileList]);
    },
    handleExceed(files, fileList, col) {
      this.$message.warning(
        `当前限制选择 ${col.itemProps.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
  },
};
</script>

<style lang="scss">
.custom-upload {
  width: 100%;
  @include flex(row, flex-start, flex-start);
}
.file-list-wrap {
  margin-left: 0.1rem;
  @include flex(row, flex-start, center);
  flex-wrap: wrap;

  .file-list-item {
    @include flex(row, center, flex-end);
    margin: 0 0.1rem;
  }

  .upload-image-item {
    width: 0.8rem;
    height: 0.8rem;

    img {
      object-fit: cover;
    }
  }

  .el-icon-delete {
    color: red;
  }

  .upload-file-item {
    width: 0.8rem;
    height: 0.8rem;
    @include flex(column, space-between, center);

    .upload-icon-item {
      font-size: 0.4rem;
    }

    .el-tooltip {
      width: 100%;
      line-height: 1.2;
    }
  }
}
</style>
