import axios from "axios";
import { Message } from "element-ui";
import router from "@/router/index";

const instance = axios.create({
  headers: {
    "Cache-Control": "no-cache",
  },
});

let baseUrl = window.g.baseUrl;
if (process.env.NODE_ENV === "development") {
  baseUrl = "proxy";
}

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let urlToken = "";

    if (!userInfo) {
      urlToken = localStorage.getItem("urlToken");
    }
    // 在发送请求之前做些什么
    if (window.g.openCancelRequest && window._axiosQueue) {
      config.cancelToken = new axios.CancelToken(function (cancel) {
        window._axiosQueue.push({ cancel });
      });
    }

    if (config.url.indexOf("/api/user/login") < 0 && userInfo) {
      config.headers.token = userInfo.token;
    } else if (config.url.indexOf("logintest") > -1 && urlToken) {
      config.headers.token = urlToken;
    }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code != 1) {
      Message({
        message: response.data.msg,
        type: "error",
      });
      return Promise.reject(response.data);
    }
    return response.data;
  },
  function (error) {
    if (error.message.indexOf("401") > -1) {
      router.push("/");
    }
    if (error.message !== "请求取消") {
      Message({
        message: error.message,
        type: "error",
      });
      // 对响应错误做点什么
      return Promise.reject(error);
    } else if (error.message == "请求取消") {
      return Promise.reject(error);
    }
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(baseUrl + url, { params })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance
      .post(baseUrl + url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
