// import Vue from 'vue'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "./utils/rem";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "./components/global/index";
import "./styles/index.scss";
import "vue2-animate/src/sass/vue2-animate.scss";
import "animate.css";
// import console from "@/utils/dateLog";
import utils from "@/utils/index";
import fontSize from "./utils/fontSize";
import "./directive/index";
import i18n from "@/i18n";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
Vue.use(VueMaterial);
if (process.env.NODE_ENV === "development") {
  // window.g.domain = "192.168.10.137:38443";
  // window.g.domain = "meet.sxclkd.cn:38443"
  // window.g.domain = "192.168.10.137:38443"
}
// Object.assign(window.console, console);

// if (window.__TAURI__) {
//   const invoke = window.__TAURI__.invoke;
//   document.addEventListener("DOMContentLoaded", () => {
//     // This will wait for the window to load, but you could
//     // run this function on whatever trigger you want
//     // setTimeout(() => {
//     invoke("close_splashscreen");
//     // }, window.g.invokeTimeout || 1000);
//   });
// }

// 设置默认主题
store.commit("setTheme", "theme-default");

moment.locale("zh-cn");
// Vue.use(ElementUI);

Vue.prototype.$Moment = moment;
Vue.prototype.$FontSize = fontSize;
Vue.prototype.$utils = utils;
Vue.prototype.$baseUrl =
  process.env.NODE_ENV === "development" ? "proxy" : window.g.baseUrl;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
