<template>
  <!-- 收藏联系人 -->
  <el-dialog
    :class="['custom-dialog-box', 'join-meeting-dialog-box', options.size]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <GlobalForm
      ref="GlobalForm"
      class="h100 info-form-box hide-scrollbar"
      :formConfig="formConfig"
      :formData="formData"
      :form="form"
    >
    </GlobalForm>
    <span slot="footer" class="dialog-footer">
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >加入分组</el-button
      >
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>
  
  <script>
import { getGrouping } from "@/api/user.js";
export default {
  mixins: [],
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      // group_id: JSON.parse(localStorage.getItem("userInfo")).group_id,
      formDataGroup: null,
      form: {
        grouping_id: "",
      },
      formConfig: {
        labelPosition: "top",
        labelWidth: this.$FontSize(1) + "px",
        rules: {
          // number: [
          //   {
          //     required: true,
          //     message: "请输入会议号",
          //     trigger: "blur",
          //   },
          // ],
        },
      },
      formData: [
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "分组",
                prop: "grouping_id",
              },
              type: "el-select",
              key: "grouping_id",
              itemProps: {
                placeholder: "请选择分组(不选择默认展示常用联系人内)",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
              valueKey: "id",
              labelKey: "name",
              options: [],
            },
          ],
        },
      ],
    };
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      // if (this.row) {
      //   // this.form = JSON.parse(JSON.stringify(this.row));
      // } else {
      // }
      getGrouping().then((res) => {
        this.formData[0].cols[0].options = res.data;
        console.log("🚀 ~ grouping_id ~ res.data:", res);
      });
    },

    confirm() {
      this.$refs.GlobalForm.$refs.form.validate((valid) => {
        if (valid) {
          // this.row
          //   ? this.$emit("onEditClose", this.form)
          //   : this.$emit("onJoinClose", this.form);
          this.$emit("onContactsClose", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("onContactsClose");
    },
  },
};
</script>
  
  <style lang="scss">
.join-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .confirm-btn {
    // width: 100%;
  }
}
</style>
  