<template>
  <!-- 加入会议 -->
  <el-dialog
    :class="['custom-dialog-box', 'join-meeting-dialog-box', options.size]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <GlobalForm
      ref="GlobalForm"
      class="h100 info-form-box hide-scrollbar"
      :formConfig="formConfig"
      :formData="formData"
      :form="form"
    >
    </GlobalForm>
    <span slot="footer" class="dialog-footer">
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >加入会议</el-button
      >
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>
  
  <script>
import { joinMeeting } from "@/api/home.js";
export default {
  mixins: [],
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      // group_id: JSON.parse(localStorage.getItem("userInfo")).group_id,
      formDataGroup: null,
      form: {
        number: null,
        nickname: JSON.parse(localStorage.getItem("userInfo")).username,
        is_camera: false,
        is_speech: false,
      },
      formConfig: {
        labelPosition: "top",
        labelWidth: this.$FontSize(1) + "px",
        rules: {
          number: [
            {
              required: true,
              message: "请输入会议号",
              trigger: "blur",
            },
          ],
          nickname: [
            {
              required: true,
              message: "请输入您的昵称",
              trigger: "blur",
            },
          ],
        },
      },
      formData: [
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "会议号",
                prop: "number",
              },
              type: "el-input",
              key: "number",
              itemProps: {
                placeholder: "请输入会议号",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "您的昵称",
                prop: "nickname",
              },
              type: "el-input",
              key: "nickname",
              itemProps: {
                placeholder: "请输入您的昵称",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              isRow: true,
              colProps: {
                span: 24,
                // class:"add"
              },
              type: "text",
              label: "会议设置",
            },
            {
              colProps: {
                span: 12,
              },
              formItemProps: {
                // label: "入会开启摄像头",
                prop: "is_camera",
              },
              type: "el-checkbox",
              name: "入会开启摄像头",
              key: "is_camera",
              itemProps: {
                placeholder: "11",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 12,
              },
              formItemProps: {
                // label: "入会开启摄像头",
                prop: "is_speech",
              },
              type: "el-checkbox",
              name: "入会开启麦克风",
              key: "is_speech",
              itemProps: {
                placeholder: "11",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
          ],
        },
      ],
    };
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.row) {
        // this.form = JSON.parse(JSON.stringify(this.row));
      } else {
      }
    },
    initFormKey() {
      this.formData.forEach((item) => {
        item.cols.forEach((col) => {
          this.$set(this.form, col.key, "");
        });
      });
    },
    confirm() {
      this.$refs.GlobalForm.$refs.form.validate((valid) => {
        if (valid) {
          // this.row
          //   ? this.$emit("onEditClose", this.form)
          //   : this.$emit("onJoinClose", this.form);
          this.$emit("onJoinClose", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("onJoinClose");
    },
  },
};
</script>
  
  <style lang="scss">
.join-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .confirm-btn {
    // width: 100%;
  }
}
</style>
  