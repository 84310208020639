export default function (url, fileName) {
  console.log("🚀 ~ url:", url)
  const fileNameArr = url.split('/')
  const name = fileNameArr[fileNameArr.length - 1]
  let link = document.createElement('a')

  link.style.display = 'none'
  link.href = url
  link.id = 'temp-download'
  link.setAttribute('download', name)
  // link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()
  document.getElementById(link.id).remove()
}
