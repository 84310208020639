<template>
  <!-- 预定会议 -->
  <el-dialog
    :class="['custom-dialog-box', 'join-meeting-dialog-box', options.size]"
    :title="options.title"
    :visible.sync="options.visible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <i class="el-icon-close close-btn-mask" @click="close"></i>
    <GlobalForm
      ref="GlobalForm"
      class="h100 info-form-box hide-scrollbar"
      :formConfig="formConfig"
      :formData="formData"
      :form="form"
    >
    </GlobalForm>
    <span slot="footer" class="dialog-footer">
      <el-button class="custom-btn confirm-btn" @click="confirm"
        >预定</el-button
      >
      <!-- <el-button class="custom-btn cancel-btn" @click="close">取 消</el-button> -->
    </span>
  </el-dialog>
</template>
  
  <script>
import { shuList1 } from "@/api/home.js";
export default {
  mixins: [],
  components: {},
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      // group_id: JSON.parse(localStorage.getItem("userInfo")).group_id,
      formDataGroup: null,
      form: {
        title: null,
        waiting_room: true,
        start_time: null,
        end_time: null,
        invite_ids: [],
        // all: [3, 4, 5],
      },
      formConfig: {
        labelPosition: "top",
        labelWidth: this.$FontSize(1) + "px",
        rules: {
          title: [
            {
              required: true,
              message: "请输入会议名称",
              trigger: "blur",
            },
          ],
          start_time: [
            {
              required: true,
              message: "请选择开始时间",
              trigger: "blur",
            },
          ],
          invite_ids: [
            {
              required: true,
              message: "请选择参会人员",
              trigger: "blur",
            },
          ],
        },
      },
      formData: [
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 18,
              },
              formItemProps: {
                label: "会议名称",
                prop: "title",
              },
              type: "el-input",
              key: "title",
              itemProps: {
                placeholder: "请输入会议名称",
                maxLength: 30,
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 6,
              },
              formItemProps: {
                label: "安全",
                prop: "waiting_room",
              },
              type: "el-checkbox",
              name: "开启等候室",
              key: "waiting_room",
              itemProps: {
                placeholder: "",
                // disabled: this.options.disabled,
              },
              itemEvents: {},
            },
          ],
        },
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            {
              colProps: {
                span: 12,
              },
              formItemProps: {
                label: "开始时间",
                prop: "start_time",
              },
              type: "el-date-picker",
              key: "start_time",
              itemProps: {
                placeholder: "请输入开始时间",
                disabled: this.options.disabled,
                type: "datetime",
                valueFormat: "yyyy-MM-dd HH:mm", //存值类型
                format: "yyyy-MM-dd HH:mm", //显示类型
              },
              itemEvents: {},
            },
            {
              colProps: {
                span: 12,
              },
              formItemProps: {
                label: "结束时间",
                prop: "end_time",
              },
              type: "el-date-picker",
              key: "end_time",
              itemProps: {
                placeholder: "请输入结束时间",
                disabled: this.options.disabled,
                type: "datetime",
                valueFormat: "yyyy-MM-dd HH:mm", //存值类型
                format: "yyyy-MM-dd HH:mm", //显示类型
              },
              itemEvents: {},
            },
          ],
        },
        {
          rowProps: {},
          rowItemProps: {
            gutter: this.$FontSize(0.2),
          },
          cols: [
            // {
            //   colProps: {
            //     span: 24,
            //   },
            //   formItemProps: {
            //     label: "所属社区",
            //     prop: "community_id",
            //   },
            //   type: "el-select",
            //   key: "community_id",
            //   itemProps: {
            //     // placeholder: "请选择社区，多选",
            //     placeholder: "请选择社区 ",
            //     disabled: this.options.disabled,
            //     // multiple: true, //多选
            //   },
            //   labelKey: "name",
            //   valueKey: "id",
            //   options: [
            //     // { name: "111", id: 1 },
            //     // { name: "222", id: 2 },
            //   ],
            //   itemEvents: {
            //     // change: this.diggingsChange,
            //   },
            // },
            {
              colProps: {
                span: 24,
              },
              formItemProps: {
                label: "邀请参会人员",
                prop: "invite_ids",
              },
              type: "el-cascader",
              key: "invite_ids",
              itemProps: {
                // placeholder: "请选择社区，多选",
                // placeholder: "请选择社区 ",
                // disabled: this.options.disabled,
                multiple: true, //多选
                // showAllLevels: false,
                emitPath: false,
              },
              labelKey: "name",
              valueKey: "id",
              options: [],
              itemEvents: {
                change: this.cascaderChange,
              },
            },
          ],
        },
      ],
    };
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTreeList();
      if (this.row) {
        this.form = JSON.parse(JSON.stringify(this.row));
        console.log("🚀 ~ init ~ this.form:", this.form);
      } else {
        this.form.start_time = this.$Moment(new Date()).format("yyyy-MM-DD HH:mm")
      }
    },
    getTreeList() {
      shuList1().then((res) => {
        this.formData[2].cols[0].options = res.data;
      });
    },
    initFormKey() {
      this.formData.forEach((item) => {
        item.cols.forEach((col) => {
          this.$set(this.form, col.key, "");
        });
      });
    },
    confirm() {
      this.$refs.GlobalForm.$refs.form.validate((valid) => {
        if (valid) {
          console.log(
            "🚀 ~ this.$refs.GlobalForm.$refs.form.validate ~ this.form:",
            this.form
          );
          this.form.invite_ids = this.form.invite_ids.join();

          // this.form
          this.row
            ? this.$emit("onEditClose", this.form)
            : this.$emit("onYDClose", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.row ? this.$emit("onEditClose") : this.$emit("onYDClose");
    },
    cascaderChange() {
      console.log("触发了");
      this.$refs.GlobalForm.$refs.form.validateField("all");
      // this.$refs.form.validateField(fieldKey); // 手动触发fileList校验规则
    },
  },
};
</script>
  
  <style lang="scss">
.join-meeting-dialog-box {
  .el-dialog__headerbtn {
    display: none;
  }

  .confirm-btn {
    width: 100%;
  }
}
</style>
  