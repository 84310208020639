<template>
  <div
    v-animate="{
      enterClass: 'animate__fadeIn',
      leaveClass: 'animate__fadeOut',
      leaveDelay: '200ms',
    }"
    class="login-box"
  >
    <div
      v-animate="{
        enterClass: 'animate__bounceInRight',
        leaveClass: 'animate__bounceOutRight',
        enterDelay: '100ms',
        leaveDelay: '100ms',
      }"
      class="login-info"
    >
      <div class="login-form-title">
        <!-- <img src="@/assets/login/logo.png" alt="" /> -->
        <img v-if="loginInfo" :src="loginInfo.logo" alt="" />
        <!-- <span v-if="loginInfo" class="line">|</span> -->
        <!-- <span>远程视频协作会商系统</span> -->
        <!-- <span v-if="loginInfo">{{ loginInfo.name }}</span> -->
      </div>
      <el-form
        ref="form"
        :model="form"
        :rules="ruleForm"
        status-icon
        class="login-form"
        @submit.native.prevent
      >
        <el-form-item label="" prop="account">
          <div class="inputName">用户名</div>
          <el-input
            type="text"
            clearable
            v-model="form.account"
            placeholder="请输入用户名"
            class="input1"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <div class="inputName">密码</div>
          <el-input
            :type="type"
            clearable
            v-model="form.password"
            auto-complete="off"
            placeholder="请输入密码"
            class="input1"
          >
            <i
              v-if="type === 'password'"
              slot="suffix"
              @click="changePasswordType"
              class="el-input__icon el-icon-lock"
            ></i>
            <i
              v-else
              slot="suffix"
              @click="changePasswordType"
              class="el-input__icon el-icon-unlock"
            >
            </i>
            ></el-input
          >
        </el-form-item>
        <el-form-item>
          <div class="remember-container">
            <el-checkbox
              class="remember"
              v-model="remember"
              @change="rememberChange"
              >记住密码</el-checkbox
            >
            <!-- <div class="backend-btn">忘记密码?</div> -->
            <!-- <el-popover
                placement="right"
                width="220"
                popper-class="workface-popper"
              >
                <p v-if="loginInfo">{{ loginInfo.pwd_hint }}</p>
                <p>
                  <span>管理员：</span>
                  <span class="remember-phone">12333333333</span>
                </p>
                <div slot="reference" class="backend-btn">忘记密码?</div>
              </el-popover> -->
            <!-- <a class="backend-btn" href="javascript:;">后台管理</a> -->
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            v-waves="{ className: 'waves-light' }"
            class="login-btn"
            type="primary"
            @click="submit"
            :loading="logining"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
      <div class="register-btn-box">
        没有账号？<span class="register-btn" @click="OnRegister">立即注册</span>
      </div>
    </div>
  </div>
</template>

<script>
import { login, configs } from "@/api/user.js";
export default {
  components: {},
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      ruleForm: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      remember: false,
      logining: false,
      loginInfo: null,
      type: "password",
    };
  },
  created() {
    document.addEventListener("keydown", this.onKeydown);
    // this.loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
    if (process.env.NODE_ENV === "development") {
      // this.form.account = "admin";
      // this.form.password = "123456";
    }
    this.init();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    init() {
      this.remember =
        localStorage.getItem("isRemember") === "true" ? true : false;

      if (this.remember && localStorage.getItem("rememberForm")) {
        this.form = JSON.parse(localStorage.getItem("rememberForm"));
      }
      //获取基础信息
      configs().then((res) => {
        console.log("🚀 ~ configs ~ res:", res);
        localStorage.setItem("loginInfo", JSON.stringify(res.data));
        this.loginInfo = res.data;
        if (res.data.name) {
          document.title = res.data.name || window.g.title;
        }
      });
    },
    onKeydown(event) {
      if (event.keyCode == 13) {
        this.submit();
      }
    },
    rememberChange(val) {
      localStorage.setItem("isRemember", val);
    },
    submit(event) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.logining = true;
          // this.$router.push("/main-layout/home");
          login(this.form)
            .then((res) => {
              localStorage.setItem(
                "userInfo",
                JSON.stringify(res.data.userinfo)
              );

              this.remember
                ? localStorage.setItem(
                    "rememberForm",
                    JSON.stringify(this.form)
                  )
                : localStorage.removeItem("rememberForm");

              const _this = this;
              this.$message({
                message: "登录成功",
                type: "success",
                duration: 1000,
                onClose() {
                  _this.$router.push("/main-layout/home");
                },
              });

              // return this.$message({
              //   message: "您无权限访问，请联系管理员",
              //   type: "error",
              // });
            })
            .catch((err) => {
              // this.$alert("用户名或密码错误!", "提示", {
              //   confirmButtonText: "确定",
              // });
            })
            .finally(() => {
              this.logining = false;
            });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
    changePasswordType() {
      this.type = this.type === "password" ? "text" : "password";
    },
    OnRegister() {
      // 跳转注册页面
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss">
.login-box {
  height: 100%;
  width: 100%;
  position: relative;
  @include flex(row, center, center);
  background: url("@/assets/login/video_BG1.png") no-repeat center center / 100%
    100%;

  // background: #132b4f;

  .login-info {
    width: px2vw(645);
    height: 100%;
    // background: #040404;
    position: fixed;
    right: 0;
    // padding: px2vh(344) px2vw(114);
    // box-sizing: border-box;
    @include flex(column, center, center);

    .login-form-title {
      width: px2vw(430);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: px2vh(60);
      img {
        padding: 0 px2vh(20);
        width: px2vw(278);
        height: px2vh(56);
      }
      .line {
        margin: 0 px2vh(10);
      }
      span {
        @include font4vh(#fff, 26, 400);
      }
    }

    .login-form {
      width: px2vw(420);
      padding: 0 px2vh(20);
      box-sizing: border-box;

      .inputName {
        @include font4vh(#fff, 16, 400);
        line-height: px2vh(28);
      }

      .remember-container {
        display: flex;
        justify-content: space-between;
        .backend-btn {
          // color: var(--clkd-color-global);
          font-family: OpenSans-Regular;
          font-size: 0.14rem;
          font-weight: normal;
          cursor: pointer;
          -webkit-user-select: none;
        }
      }

      .login-btn {
        width: 100%;
        height: px2vh(50);
        // background-color: var(--clkd-color-global);
        border: 0;
        @include font4vh(#fff, 14, 400);
      }

      .input1 {
        width: 100%;
        height: px2vh(50);
        margin-top: 0.07rem;
        .el-input__suffix {
          height: 0;
        }
      }
    }
  }

  .register-btn-box {
    margin-top: px2vh(20);
    font-size: 14px;
    .register-btn {
      color: #66b1ff;
      cursor: pointer;
    }
  }
}
</style>
