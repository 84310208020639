import { get, post } from "../services/http";

// 树结构 /api/department/shulist
export function shuList(params = {}) {
  return post(`/api/department/shulist`, params);
}
// 树结构 /api/department/shulist1
export function shuList1(params = {}) {
  return post(`/api/department/shulist1`, params);
}

// 收藏取消联系人 /api/department/contacts
export function contacts(params = {}) {
  return post(`/api/department/contacts`, params);
}


// 快速会议 /api/meeting/quickMeeting
export function quickMeeting(params = {}) {
  return post(`/api/meeting/quickMeeting`, params);
}
// 离开会议调用 /api/meeting/likaiMeeting
export function likaiMeeting(params = {}) {
  return post(`/api/meeting/likaiMeeting`, params);
}

// 根据状态返回 会议列表 /api/meeting/statusList
export function statusList(params = {}) {
  return post(`/api/meeting/statusList`, params);
}

// 首页消息合并列表接口 /api/meeting/sumList
export function sumList(params = {}) {
  return post(`/api/meeting/sumList`, params);
}

// 等候区列表 /api/meeting/meetingWaitingArea
export function meetingWaitingArea(params = {}) {
  return post(`/api/meeting/meetingWaitingArea`, params);
}

// 离开等候区 /api/meeting/meetingWaitingAreaDel
export function meetingWaitingAreaDel(params = {}) {
  return post(`/api/meeting/meetingWaitingAreaDel`, params);
}

// 离开 /api/meeting/meetingWaitingAreaStatus
// 参数id    status：0或者1
export function meetingWaitingAreaStatus(params = {}) {
  return post(`/api/meeting/meetingWaitingAreaStatus`, params);
}

// 会议列表 /api/meeting/list  :0=待开始的会议,1=进行中的会议,2=结束的会议
export function meetingList(params = {}) {
  return post(`/api/meeting/list`, params);
}
// 预约会议 /api/meeting/add
export function meetingAdd(params = {}) {
  return post(`/api/meeting/add`, params);
}
// 修改预约会议 /api/meeting/edit
export function meetingEdit(params = {}) {
  return post(`/api/meeting/edit`, params);
}
// 取消会议 /api/meeting/del
export function meetingDel(params = {}) {
  return post(`/api/meeting/del`, params);
}
//  加入会议 /api/meeting/joinMeeting
export function joinMeeting(params = {}) {
  return post(`/api/meeting/joinMeeting`, params);
}
// 结束会议 /api/meeting/endMeeting
export function endMeeting(params = {}) {
  return post(`/api/meeting/endMeeting`, params);
}
// 列表结束会议 /api/meeting/endMeeting1
export function endMeeting1(params = {}) {
  return post(`/api/meeting/endMeeting1`, params);
}
// 会议详情 /api/meeting/detail
export function meetingDetail(params = {}) {
  return post(`/api/meeting/detail`, params);
}




// 消息列表 /api/meeting/messageList
export function messageList(params = {}) {
  return post(`/api/meeting/messageList`, params);
}
// 消息已读未读操作 /api/meeting/messageStatus
export function messageStatus(params = {}) {
  return post(`/api/meeting/messageStatus`, params);
}
// 未读会议消息条数 /api/meeting/userInfo
export function unreadMessage(params = {}) {
  return post(`/api/meeting/unreadMessage`, params);
}
// 会员详情 /api/user/userInfo
export function userInfo(params = {}) {
  return post(`/api/user/userInfo`, params);
}
// 修改会员个人信息 /api/user/profile
export function profile(params = {}) {
  return post(`/api/user/profile`, params);
}

// 会议室内邀请 /api/department/invite
export function invite(params = {}) {
  return get(`/api/department/invite`, params);
}

//  会议邀请消息弹窗 /api/department/meetingInvite
export function meetingInvite(params = {}) {
  return get(`/api/department/meetingInvite`, params);
}

//  会议邀请消息弹窗 /api/meeting_transcribe/addFeedBack
export function addFeedBack(params = {}) {
  return get(`/api/meeting_transcribe/addFeedBack`, params);
}

//  添加云录制视频 /api/meeting_transcribe/add
export function meeting_transcribe_add(params = {}) {
  return get(`/api/meeting_transcribe/add`, params);
}

//  某个会议下的所有云录制视频列表 /api/meeting_transcribe/list
export function meeting_transcribe_list(params = {}) {
  return get(`/api/meeting_transcribe/list`, params);
}

//  删除 /api/meeting_transcribe/del
export function meeting_transcribe_del(params = {}) {
  return get(`/api/meeting_transcribe/del`, params);
}